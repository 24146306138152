import React, { useEffect } from "react";

interface AuthContextType {
    authorized: boolean;
    login: (email: string, password: string) => Promise<{ success: boolean; message?: string }>;
}

// Create the context with the updated type
export const AuthContext = React.createContext<AuthContextType>({
    authorized: false,
    login: async () => ({ success: false }) // Provide a default implementation
});
type Props = {
    children: React.ReactNode;
};
export const AuthProvider: React.FC<Props> = (({ children }) => {
    const [authorized, setAuthorized] = React.useState(false);

    useEffect(() => {
        const first_name = window.localStorage.getItem("first_name");
        const email = window.localStorage.getItem("email");
        const balance = window.localStorage.getItem("balance");

        if (first_name && email && balance) {
            // User is considered authorized if data is found in local storage
            setAuthorized(true);
        } else {
            // User is not authorized
            setAuthorized(false);
        }
    }, []);

    async function login(email: string, password: string) {
        try {
            const response = await fetch('https://coin24.store/crypto_exchange_api/login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.status === 'success') {
                window.localStorage.setItem('userId', data.userId);
                window.localStorage.setItem('first_name', data.first_name);
                window.localStorage.setItem('email', data.email);
                window.localStorage.setItem('balance', data.balance.toString());

                setAuthorized(true);
                return { success: true }; // Indicate success to caller
            } else {
                throw new Error(data.message); // Throw error message from backend
            }
        } catch (error) {
            const errorMessage = (error as Error).message;
            console.error('Network or server error:', errorMessage);
            return { success: false, message: errorMessage };
        }
    }

    return (
        <AuthContext.Provider value={{ authorized, login }}>
            {children}
        </AuthContext.Provider>);
})
