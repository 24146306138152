import React, { useContext, useEffect, useState } from 'react';
import { Link, Outlet, NavLink } from 'react-router-dom';
import "./styles/App.scss";
import './styles/form.scss';
import classNames from 'classnames';
import { AuthContext } from './store/AuthContext';

const getLinkClass = ({ isActive }: { isActive: boolean }) => classNames('header__nav-item', {
  'header__nav-item--active': isActive,
});

export const App: React.FC = () => {
  const { authorized } = useContext(AuthContext);

  console.log('isAuthorized: ', authorized);
  const userId = localStorage.getItem('userId');
  const userFirstName = localStorage.getItem('first_name');
  const [userBalance, setUserBalance] = useState(0);

  const changeUserBalance = (newState: number) => {
    setUserBalance(newState);
  };

  useEffect(() => {
    const overlay = document.getElementById('overlay');

    window.addEventListener('hashchange', () => {
      if (window.location.hash === '#menu') {
        document.body.classList.add('page__body--with-menu');
        overlay?.classList.add('overlay--active');
      } else {
        document.body.classList.remove('page__body--with-menu');
        overlay?.classList.remove('overlay--active');
      }
    });

    const menuToggle = document.querySelector('#menu-toggle');

    if (menuToggle) {
      menuToggle.addEventListener('click', function () {
        // @ts-ignore: Unreachable code error
        this.classList.toggle('active');
      });
    }
  })

  useEffect(() => {
    if (authorized) {
      // Fetch the user's balance from the PHP API
      const fetchBalance = async () => {
        try {
          const response = await fetch('https://coin24.store/crypto_exchange_api/getUserBalance.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: userId }),
          });

          const data = await response.json();

          console.log('data', data);

          if (data.status === 'success') {
            setUserBalance(Number(data.balance));
          }
        } catch (error) {
          console.log('Error fetching balance. Please try again later.');
        }
      };

      fetchBalance();
    }
  }, [authorized]); // Dependency array to run useEffect when these change

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="header__top">
            <Link to="/" className="header__logo">
              <img src="/images/logo.svg" alt="" className='header__logo-image' />
              <div className="header__logo-block">
                <h2 className="header__logo-title">Coin24</h2>
                <p className="header__logo-text">Cryptocurrency exchange</p>
              </div>
            </Link>
            <div className="header__nav header__nav--desktop">
              <NavLink to="/info" className={getLinkClass}>Information</NavLink>
              <NavLink to="/contacts" className={getLinkClass}>Contacts</NavLink>
              {authorized &&
                (<NavLink to="/withdraw" className={getLinkClass}>Withdraw</NavLink>)}
              {authorized ?
                (
                  <div className="header__user header__user--align-end">
                    <h3 className="header__user-name">{userFirstName}</h3>
                    <p className="header__user-balance"><span className="header__user-balance--bold">Balance:</span> {userBalance}$</p>
                  </div>
                ) :
                (<NavLink to="/login" className={getLinkClass}>Login</NavLink>)}
            </div>
            <div className="header__nav header__nav--mobile">
              <a href="#menu" className="header__menu-link" id='menu-toggle' />
            </div>
          </div>

        </div>
      </header>
      <nav className="page__menu menu" id="menu">
        <div className="menu__container">
          <div className="menu__close">
            <a href="#" className="menu__close-link">
            </a>
          </div>
          {authorized ?
            (
              <div className="header__user header__user--align-start">
                <h3 className="header__user-name">{userFirstName}</h3>
                <p className="header__user-balance"><span className="header__user-balance--bold">Balance:</span> {userBalance}$</p>
              </div>
            ) :
            (<NavLink to="/login/#home" className={getLinkClass}>Login</NavLink>)}
          <hr className="line" />
          <ul className="menu__list">
            <li className="menu__item">
              <NavLink to="/info/#home" className={getLinkClass}>Information</NavLink>
            </li>
            <li className="menu__item">
              <NavLink to="/contacts/#home" className={getLinkClass}>Contacts</NavLink>
            </li>
            {authorized && (
              <li className="menu__item">
                <NavLink to="/withdraw/#home" className={getLinkClass}>Withdraw</NavLink>
              </li>
            )}
          </ul>
        </div>
      </nav>
      <main className="main">
        <Outlet context={{ changeUserBalance, userBalance, userId }} />
      </main>

      <footer className="footer">
        <div className="container">
          <div className="footer__box">
            <div className="footer__box-item">
              <h3 className="footer__title">Information</h3>
              <ul className="footer__list">
                <li className="footer__list-item">
                  <Link to="/about-us" className="footer__list-link">About Us</Link>
                </li>
                <li className="footer__list-item">
                  <Link to="/rates" className="footer__list-link">Rates</Link>
                </li>
                <li className="footer__list-item">
                  <Link to="/reviews" className="footer__list-link">Reviews</Link>
                </li>
                <li className="footer__list-item">
                  <Link to="/partners" className="footer__list-link">Referral Program</Link>
                </li>
                <li className="footer__list-item">
                  <Link to="/client-manual" className="footer__list-link">User Manual</Link>
                </li>
                <li className="footer__list-item">
                  <Link to="/faq" className="footer__list-link">FAQ</Link>
                </li>
              </ul>
            </div>
            <div className="footer__box-item">
              <h3 className="footer__title">Documents</h3>
              <ul className="footer__list">
                <li className="footer__list-item">
                  <Link to="/useterms" className="footer__list-link">User Agreement</Link>
                </li>
                <li className="footer__list-item">
                  <Link to="/privacy" className="footer__list-link">Privacy Policy</Link>
                </li>
                <li className="footer__list-item">
                  <Link to="/kyc_and_aml" className="footer__list-link">KYC and AML policy</Link>
                </li>
                <li className="footer__list-item">
                  <Link to="/exchange-regulation" className="footer__list-link">Exchange Regulation</Link>
                </li>
                <li className="footer__list-item">
                  <Link to="/cookie-policy" className="footer__list-link">Cookie Policy</Link>
                </li>
                <li className="footer__list-item">
                  <Link to="/card-verification" className="footer__list-link">Card Verification</Link>
                </li>
              </ul>
            </div>
            <div className="footer__box-item">
              <h3 className="footer__title">Hours</h3>
              <ul className="footer__list">
                <li className="footer__list-item">
                  Service works 24/7
                </li>
                <li className="footer__list-item">
                  Technical support 08:00-24:00 (UTC+2)
                </li>
              </ul>
            </div>
            <div className="footer__box-item">
              <h3 className="footer__title">Our e-mail</h3>
              <ul className="footer__list mb-25">
                <li className="footer__list-item">
                  <a href="mailto:info@coin24.store" className="footer__list-link">info@coin24.store</a>
                </li>
              </ul>

              <h3 className="footer__title">Stay tuned</h3>
              <div className="footer__social-media">
                <a href="tg://resolve?domain=coin24io" className="footer__social-media_link footer__social-media_link--telegram" target='_blank' />
                <a href="https://www.facebook.com/coin24io" className="footer__social-media_link footer__social-media_link--facebook" />
                <a href="https://www.instagram.com/coin24.io/" className="footer__social-media_link footer__social-media_link--instagram" />
                <a href="https://www.threads.net/@coin24.io" className="footer__social-media_link footer__social-media_link--threads" />
                <a href="https://twitter.com/Coin24io" className="footer__social-media_link footer__social-media_link--twitter" />
              </div>
            </div>
          </div>

          <div className="footer__merchant">
            <div className="footer__merchant-box">
              <div className="footer__merchant-image footer__merchant-image--visa"></div>
              <div className="footer__merchant-image footer__merchant-image--visa-secure"></div>
              <div className="footer__merchant-image footer__merchant-image--mastercard"></div>
              <div className="footer__merchant-image footer__merchant-image--mastercard-id-check"></div>
            </div>
            <img src="/images/edgeport_sign.svg" alt="edge port" className="footer__merchant-port" />
          </div>

          <p className="footer__caption">© Coin24.store, 2018—2024. All rights reserved</p>
        </div>
      </footer>
      <div className="overlay" id="overlay"></div>
    </>
  );
}

export default App;
