import React, { Component, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import coinsArr from '../../api/coins.json';
import uniqueCoinsArr from '../../api/uniqueCoins.json';
import classNames from 'classnames';
import { AuthContext } from '../../store/AuthContext';

interface AppContextType {
  changeUserBalance: (newBalance: number) => void;
  userBalance: number;
  userId: number;
}


export const WithdrawPage: React.FC = () => {
  const { authorized } = useContext(AuthContext);
  const minCoinsCost = 20;

  const [selectedCoinToExchange, setSelectedCoinToExchange] = useState('BTC');
  const [selectedCoinsNum, setSelectedCoinsNum] = useState('');
  const [selectedCoinCost, setSelectedCoinCost] = useState(0);

  const [visibleTransferForm, setVisibleTransferForm] = useState(false);

  const [depositWallet, setDepositWallet] = useState('');
  const [depositWalletError, setDepositWalletError] = useState(false);
  const [coinsNumErrorMessage, setCoinsNumErrorMessage] = useState(false);
  const [tearmsAgreed, setTermsAgreed] = useState(false);

  const [isFormConfirmed, setIsFormConfirmed] = useState(false);

  const navigate = useNavigate();

  const { changeUserBalance, userBalance, userId } = useOutletContext<AppContextType>();

  useEffect(() => {
    const coinName = selectedCoinToExchange.split(' ').length === 2 ? selectedCoinToExchange.split(' ')[0] : selectedCoinToExchange;

    if (coinName === 'USDT') {
      if (selectedCoinCost !== 1) {
        setSelectedCoinCost(1);
      }

      return;
    }

    fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${coinName}USDT`, {
      method: "GET"
    }).then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();  // Parse the JSON body from the response
    })
      .then(data => {
        return setSelectedCoinCost(Number(data.price));  // Log the parsed JSON data
      })
  }, [selectedCoinToExchange]);

  return (
    <section className="withdraw" >
      <div className="container">
        {
          !isFormConfirmed ? (
            <>
              <h1 className='withdraw__title'>
                Withdraw
              </h1>
              <div className="exchange__block">
                <div className="exchange__box">
                  <div className="exchange__box-tab">
                    {coinsArr.map((coin) => {
                      return (
                        <button
                          className={classNames("exchange__coin", {
                            'exchange__coin--current': coin.currency === selectedCoinToExchange
                          })}
                          onClick={() => {
                            setSelectedCoinToExchange(coin.currency);
                          }}>
                          <div className={`exchange__coin-icon exchange__coin-icon--${coin.shortName}`} />
                          <h3 className="exchange__coin-name">{coin.fullName}</h3>
                          <p className="exchange__coin-currency">{coin.currency}</p>
                        </button>
                      );
                    })}
                  </div>
                </div>
                <div className="exchange__wrapper" data-currency={selectedCoinToExchange}>
                  <div className="exchange__wrapper-input">
                    <input
                      type="number"
                      name="depositNum"
                      id="depositNum"
                      className='exchange__input'
                      value={selectedCoinsNum}

                      defaultValue=''
                      placeholder={`${minCoinsCost / selectedCoinCost}`}
                      onChange={(e) => {
                        const newCoinsNum = Number(e.target.value)
                        if ((selectedCoinCost * newCoinsNum < minCoinsCost || selectedCoinCost * newCoinsNum > userBalance) && !coinsNumErrorMessage) {
                          setCoinsNumErrorMessage(true);
                        }

                        if (selectedCoinCost * newCoinsNum >= minCoinsCost && selectedCoinCost * newCoinsNum <= userBalance && coinsNumErrorMessage) {
                          setCoinsNumErrorMessage(false);
                        }

                        setSelectedCoinsNum(e.target.value);
                      }}
                      onFocus={() => {
                        if (selectedCoinCost * Number(selectedCoinsNum) < minCoinsCost && !coinsNumErrorMessage) {
                          setCoinsNumErrorMessage(true);
                        }
                      }}
                    />
                  </div>
                </div>
                {coinsNumErrorMessage && (
                  <>
                    <p className="exchange__wrapper-error">Min number of {selectedCoinToExchange} to withdraw = {minCoinsCost / selectedCoinCost}</p>
                    <p className="exchange__wrapper-error">Max number of {selectedCoinToExchange} available to withdraw = {userBalance / selectedCoinCost}</p>
                  </>)}
                <button
                  className="exchange__deposit"
                  onClick={() => {
                    if ((selectedCoinCost * Number(selectedCoinsNum) < minCoinsCost || selectedCoinCost * Number(selectedCoinsNum) > userBalance) && !coinsNumErrorMessage) {
                      setCoinsNumErrorMessage(true);
                      return 0;
                    }

                    if (!authorized) {
                      return navigate('/login');
                    }

                    if (!visibleTransferForm) {
                      setVisibleTransferForm(true);
                    }
                  }}
                  disabled={coinsNumErrorMessage}
                >
                  Withdraw
                </button>
              </div>

              {visibleTransferForm && (
                <div className="exchange__receipt">
                  <h3 className="exchange__receipt-title exchange__receipt-title--orange">
                    Receipt details:
                  </h3>
                  <div className="exchange__receipt-wrapper" data-currency={selectedCoinToExchange}>
                    <div className="exchange__wrapper-input">
                      <label htmlFor="walletInput" className="exchange__receipt-title">
                        Wallet:
                      </label>
                      <input
                        type="text"
                        name="walletInput"
                        id="walletInput"
                        className='exchange__input exchange__receipt-input'
                        value={depositWallet}
                        placeholder="TU6JdEDQGPus64LTMksvnxF2cv4FQrXPCa"
                        onChange={(e) => {
                          if (e.target.value.length < 16 && !depositWalletError)
                            setDepositWalletError(true);

                          if (e.target.value.length >= 16 && depositWalletError)
                            setDepositWalletError(false);

                          setDepositWallet(e.target.value);
                        }}
                        onFocus={() => {
                          if (selectedCoinCost * Number(selectedCoinsNum) < minCoinsCost && !coinsNumErrorMessage) {
                            setCoinsNumErrorMessage(true);
                          }
                        }}
                      />
                    </div>
                    {depositWalletError && (
                      <p className="exchange__wrapper-error">Wallet name is invalid. Please change it</p>
                    )}
                  </div>
                  <div className="exchange__receipt-agreement">
                    <input name="isTermsAccepted" id="isTermsAccepted" type="checkbox" className="exchange__receipt-agreement_button" checked={tearmsAgreed} onClick={() => {
                      setTermsAgreed(!tearmsAgreed);
                    }} />
                    <label htmlFor="isTermsAccepted" className='exchange__receipt-agreement-label'>
                      Read and agree with {' '}
                      <a href="/useterms" className="exchange__receipt-agreement-label--link" target="_blank">user agreement</a>,  {' '}
                      <a href="/privacy" className="exchange__receipt-agreement-label--link" target="_blank">privacy policy</a>,  {' '}
                      <a href="/kyc-and-aml" className="exchange__receipt-agreement-label--link" target="_blank">KYC and AML policy</a>,  {' '}
                      <a href="/exchange-regulations" className="exchange__receipt-agreement-label--link" target="_blank">exchange regulations</a>,  {' '}
                      <a href="/cookies-policy" className="exchange__receipt-agreement-label--link" target="_blank">Cookie Policy</a> {' '}
                    </label>
                  </div>
                  <div className="exchange__receipt-box">
                    <p className="exchange__receipt-text">
                      In accordance with international law, Coin24 adheres to a number of rules and implements a number of procedures aimed at preventing the use of the Service
                      for the purpose of conducting money laundering operations, as well as the implementation of other illegal operations. If you do not pass the AML check, contact the manager.
                      If you need to clarify the data we may request additional documents and/or data on the origin of the assets. The exchanger sets the exchange rate according to the Whitebit
                      base rate after the funds are credited to the order. The funds are considered received after the Manager credits them or when the order receives the required number of {' '}
                      <a href="/network-confirmations" className="exchange__receipt-link" target="_blank">network confirmations</a>
                    </p>
                    <p className="exchange__receipt-text">
                      By clicking the "confirm" button, you confirm your agreement with the rules of using the service.
                    </p>
                    <button
                      className="exchange__deposit"
                      onClick={async () => {
                        if (depositWallet.length < 16 && !depositWalletError)
                          return setDepositWalletError(true);


                        if (!visibleTransferForm) {
                          setVisibleTransferForm(true);
                        }

                        const usdtWithdrawAmount = selectedCoinCost * Number(selectedCoinsNum);

                        if (usdtWithdrawAmount < 20 || usdtWithdrawAmount > userBalance) {
                          return setCoinsNumErrorMessage(true);
                        }

                        try {
                          const fetchObject = {
                            user_id: userId,  // Ensure camelCase matches PHP expectations
                            currency: selectedCoinToExchange,
                            amount: selectedCoinsNum,
                            wallet_name: depositWallet,
                            usdtAmount: usdtWithdrawAmount.toFixed(2), // Key name in PHP should match this
                          };
                          console.log('fetch data: ', fetchObject);
                          const response = await fetch('https://coin24.store/crypto_exchange_api/saveWithdraw.php', {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(fetchObject),
                          });

                          const data = await response.json();

                          console.log('data', data);

                          if (data.status === 'success') {
                            setIsFormConfirmed(true);
                            changeUserBalance(Number(userBalance - Number(usdtWithdrawAmount.toFixed(2))));
                          } else {
                            alert(data.message);
                          }
                        } catch (error) {
                          console.log('Error fetching balance. Please try again later.');
                        }
                      }}
                      disabled={depositWalletError || !tearmsAgreed || coinsNumErrorMessage}
                    >
                      confirm
                    </button>
                  </div>
                </div>
              )}

              <div className="exchange__alarm">
                Attention! All coin24.store users are requested to read {' '}
                <Link className='exchange__alarm-link' to="/services-to-the-exchange">important information</Link>
              </div>
            </>
          ) : (
            <div className="withdraw__success-box">
              <h1 className="title">Your funds were successfully withdrawed</h1>
            </div>
          )}
      </div>
    </section >
  );
}

export default WithdrawPage;