import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './LoginPage.scss';
import { AuthContext } from '../../store/AuthContext';
import classNames from 'classnames';

export const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const { authorized, login } = useContext(AuthContext);

  useEffect(() => {
    if (authorized) {
      setTimeout(() => {

        return navigate('/');
      }, 500)
    }
  })

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent the default form submission

    // Create a payload object to send to the backend

    if (!email.length || !password.length || !validateEmail(email)) {
      setEmailError(true);
      setPasswordError(true);

      if (!validateEmail(email)) {
        setErrorMessage('Invalid email format!');
      }

      return 0;
    }

    try {
      const result = await login(email, password);

      setTimeout(() => {

        if (result.success) {
          setEmail('');
          setPassword('');
          setTimeout(() => {
            navigate('/'); // Navigate to home page
          }, 500);
        } else {

          setErrorMessage(result.message ? result.message : 'An error occured, try later'); // Set the error message to be displayed
        }

      }, 500);
    } catch (error) {
      console.error('Network or server error:', error);
      setErrorMessage(error as string);
    }
  };

  const validateEmail = (email: string) => {
    // Regex to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <section className="register">
      <div className="container">
        <h1 className="title register__title">Authorization
        </h1>
        <form action="GET" className="form">
          <h3 className="form__title">Welcome!</h3>
          <label htmlFor="email" className="form__label">E-mail:</label>
          <div className={classNames("form__block", {
            'form__block--error': emailError
          })}>
            <input
              type="email"
              className={classNames("form__input", {
                'form__input--error': emailError
              })}
              name='email'
              required
              id='email'
              value={email}
              placeholder={emailError ? 'Email is required!' : 'E-mail'}
              onBlur={(e) => {
                if (!e.target.value.length) {
                  setEmailError(true);
                }
              }}
              onChange={(e) => {

                if (!e.target.value.length) {
                  setEmailError(true);
                } else if (!validateEmail(e.target.value)) {
                  setEmailError(true);
                  setErrorMessage('Invalid email format!');
                } else {
                  setEmailError(false);

                  if (errorMessage === 'Invalid email format!') {
                    setErrorMessage('');
                  }
                }

                setEmail(e.target.value);
              }}
            />
          </div>
          <label htmlFor="password" className="form__label">Password:</label>
          <div className={classNames("form__block", {
            'form__block--error': passwordError
          })}>
            <input
              type="password"
              className={classNames("form__input", {
                'form__input--error': passwordError
              })}
              name='password'
              required
              id='password'
              value={password}
              onBlur={(e) => {
                if (!e.target.value.length) {
                  setPasswordError(true);
                }
              }}
              placeholder={passwordError ? 'Password is required!' : 'Password'}

              onChange={(e) => {
                if (!e.target.value.length) {
                  setPasswordError(true);
                } else {
                  setPasswordError(false);
                }

                setPassword(e.target.value);
              }}
            />
          </div>
          <p className="form__agreement">
            By clicking the "Login" button, you confirm your agreement with the service <Link to="/useterms" className="form__link">terms of use.</Link>
          </p>

          <div className="form__box">
            <Link to="/registration" className="form__button form__button--link">Registration</Link>
            <input
              type="submit"
              value="Login"
              className="form__button form__button--submit"
              onClick={handleLogin}
            />
          </div>
          {
            (emailError || passwordError || !!errorMessage.length) && (
              <p className="form__error-msg">
                {
                  (passwordError || (emailError && !errorMessage.length)) ? 'All fields are required!!' : errorMessage
                }
              </p>
            )
          }
        </form>
      </div>
    </section>
  );

}

export default LoginPage;