import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { App } from "./App";
import { HomePage } from "./pages/HomePage";
import { LoginPage } from './pages/LoginPage';
import { AuthProvider } from "./store/AuthContext";
import { ServicesToTheExchange } from "./pages/ServicesToTheExchange";
import { RegisterPage } from "./pages/RegisterPage";
import { WithdrawPage } from "./pages/WithdrawPage";

export const Root = () => (
  <Router>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<HomePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="registration" element={<RegisterPage />} />
          <Route path="services-to-the-exchange" element={<ServicesToTheExchange />} />
          <Route path="withdraw" element={<WithdrawPage />} />
          <Route path="*" element={
            <div className="container loader__container">
              <div className="loader"></div>
            </div>
          } />
        </Route>
      </Routes>
    </AuthProvider>
  </Router>);
