import React, { Component } from 'react';
import './ServicesToTheExchange.css'
export class ServicesToTheExchange extends Component {
  componentDidMount() {
    window.localStorage.setItem("first_name", 'SOSO');
  }

  render() {
    return (
      <section className='services-to-exchange'>
        <div className="container">
          <h2 className='services-to-exchange__title'>The process of providing cryptocurrency exchange services</h2>
          <img className='services-to-exchange__img' src="/images/scam-person.svg" alt="" />
          <h2 className='services-to-exchange__sub__title'>Dear clients! On this page you can find out all the necessary information regarding the exchange service on our service.</h2>
          <ol>
            <b>To exchange cryptocurrency on the Coin24.io website, you need to know a few key points:</b>
            <ul>
              <li className='services-to-exchange__ul1'>To buy or sell, you need to register on the site and create your personal account.</li>
              <li className='services-to-exchange__ul1'><strong>All documents and photos must be uploaded only on the site</strong> through a special form. Photos or documents that the client sends to the manager via chat or messenger are not accepted. Passing verification is the responsibility of the client.</li>
              <li className='services-to-exchange__ul1'>Throughout the procedure, you will be accompanied by a personal manager who will explain and answer all questions, if any. All questions and all information remain in the chat with the manager on the site.</li>
              <li className='services-to-exchange__ul1'>The procedure for exchanging and crediting cryptocurrency or any other currency is carried out only to the wallet or card specified in the order. It is not possible to change the details after the order has been submitted.</li>
            </ul>
          </ol>
          <div className="services-to-exchange__block">
            <h2 className='services-to-exchange__last__title'>The main thing!</h2>
            <p className='services-to-exchange__penis'>The order is made <b>only on the site</b>  and only through your account.</p>
            <p className='services-to-exchange__penis'>Orders are not accepted through social networks and instant messengers. (Our social networks are only information channels. All information on the exchange remains in your personal account and interaction is possible only through a ticket with the manager on the site.) <b>Do not disclose </b> your information in telegram or in other places. If you are asked to send: the order number, the number of your electronic wallet or send a link to pay - these are attempts by scammers to deceive you. If such a situation has occurred, then you must warn your manager or site technical support about such an incident.</p>
            <p className='services-to-exchange__penis'>The Coin24.io exchange service is based on the privacy and security of its customers, so be careful and check the accuracy of the information. All official and reliable information, as well as contacts of managers in Telegram, you can find out only on the site. Unfortunately, there are a lot of scammers now, so carefully check all the information to protect yourself.</p>
          </div>
          <br />
          <div className="services-to-exchange__block">
          <h2 className='services-to-exchange__last__title'> ATTENTION! </h2>
          <p className='services-to-exchange__penis'>This service does not work with the list of crypto exchanges, platforms and companies below, and does not accept cryptocurrency payments from the following services:</p>
           <ul className='ul_last'>
            <li>Garantex</li>
            <li>Сapitalist.net;</li>
            <li>Tornado Cash;</li>
            <li>Hydra;</li>
            <li>Blender.io;</li>
            <li>Lazarus Group;</li>
            <li>Genesis Market;</li>
            <li>ChipMixer;</li>
            <li>Shinbad.io;</li>
            <li>CommEx;</li>
            <li>Rapira.</li>
           </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default ServicesToTheExchange;