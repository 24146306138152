import React, { Component, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import coinsArr from '../../api/coins.json';
import uniqueCoinsArr from '../../api/uniqueCoins.json';
import classNames from 'classnames';
import { AuthContext } from '../../store/AuthContext';


export const HomePage: React.FC = () => {
  const { authorized } = useContext(AuthContext);
  const minCoinsCost = 20;

  const userId = localStorage.getItem('userId');

  const [exchangesNum, setExchangesNum] = useState(0);
  const [customersNum, setCustomersNum] = useState(0);
  const [yearsNum, setYearsNum] = useState(0);
  const [totalCoinsNum, setTotalCoinsNum] = useState(0);
  const [isCountingStarted, setIsCountingStarted] = useState(false);
  const [selectedCoinToExchange, setSelectedCoinToExchange] = useState('BTC');
  const [selectedCoinsNum, setSelectedCoinsNum] = useState('');
  const [selectedCoinCost, setSelectedCoinCost] = useState(0);

  const [visibleTransferForm, setVisibleTransferForm] = useState(false);

  const [depositWallet, setDepositWallet] = useState('');
  const [depositWalletError, setDepositWalletError] = useState(false);
  const [coinsNumErrorMessage, setCoinsNumErrorMessage] = useState(false);
  const [tearmsAgreed, setTermsAgreed] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const coinName = selectedCoinToExchange.split(' ').length === 2 ? selectedCoinToExchange.split(' ')[0] : selectedCoinToExchange;

    if (coinName === 'USDT') {
      if (selectedCoinCost !== 1) {
        setSelectedCoinCost(1);
      }

      return;
    }

    fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${coinName}USDT`, {
      method: "GET"
    }).then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();  // Parse the JSON body from the response
    })
      .then(data => {
        return setSelectedCoinCost(Number(data.price));  // Log the parsed JSON data
      })
  }, [selectedCoinToExchange]);


  useEffect(() => {
    const coinName = selectedCoinToExchange.split(' ').length === 2 ? selectedCoinToExchange.split(' ')[0] : selectedCoinToExchange;

    // fetch(`https://api.binance.com/api/v3/ticker/24hr?symbols=["BTCUSDT","BNBUSDT"]`, {
    //   method: "GET"
    // }).then(response => {
    //   if (!response.ok) {
    //     throw new Error(`HTTP error! status: ${response.status}`);
    //   }
    //   return response.json(); // Parse the JSON body from the response
    // })
    //   .then(data => {
    //     console.log(data);
    //   });

    fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${coinName}USDT`, {
      method: "GET"
    }).then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();  // Parse the JSON body from the response
    })
      .then(data => {
        console.log(coinName, data);
        return setSelectedCoinCost(Number(data.price));  // Log the parsed JSON data
      });

    const section = document.querySelector<HTMLElement>('.numbers-count')!;

    const onScroll = () => {
      if (section.offsetTop >= window.scrollY && !isCountingStarted) {
        setIsCountingStarted(true);

        const time = 6000;

        const exchangesCount = setInterval(() => {
          setExchangesNum((prev) => {
            if (prev + 1 >= 300) {
              clearInterval(exchangesCount);
              return 300;
            }
            return prev + 1;
          });
        }, time / 300);

        const clientsCount = setInterval(() => {
          setCustomersNum((prev) => {
            if (prev + 1 >= 20) {
              clearInterval(clientsCount);
              return 20;
            }
            return prev + 1;
          });
        }, time / 20);

        const yearsCount = setInterval(() => {
          setYearsNum((prev) => {
            if (prev + 1 >= 13) {
              clearInterval(yearsCount);
              return 13;
            }
            return prev + 1;
          });
        }, time / 13);

        const coinsCount = setInterval(() => {
          setTotalCoinsNum((prev) => {
            if (prev + 1 >= 50) {
              clearInterval(coinsCount);
              return 50;
            }
            return prev + 1;
          });
        }, time / 50);
      }

    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [isCountingStarted,])

  return (<>
    <section className="exchange">
      <div className="container">
        <h1 className='exchange__title'>Automatic cryptocurrency exchange at the best rate in Ukraine, Poland, Czech Republic and
          Georgia: buy and sell Bitcoin (BTC), exchange tether, buy usdt, Ethereum (ETH), Litecoin (LTC),
          and other cryptocurrencies.
        </h1>
        <p className="exchange__subtitle">Coin24.io is a reliable, confidential, profitable, and fastest e-currency exchange service.</p>
        <div className="exchange__block">
          <div className="exchange__box">
            <div className="exchange__box-tab">
              {coinsArr.map((coin) => {
                return (
                  <button
                    className={classNames("exchange__coin", {
                      'exchange__coin--current': coin.currency === selectedCoinToExchange
                    })}
                    onClick={() => {
                      setSelectedCoinToExchange(coin.currency);
                    }}>
                    <div className={`exchange__coin-icon exchange__coin-icon--${coin.shortName}`} />
                    <h3 className="exchange__coin-name">{coin.fullName}</h3>
                    <p className="exchange__coin-currency">{coin.currency}</p>
                  </button>
                );
              })}
            </div>
          </div>
          <div className="exchange__wrapper" data-currency={selectedCoinToExchange}>
            <div className="exchange__wrapper-input">
              <input
                type="number"
                name="depositNum"
                id="depositNum"
                disabled={visibleTransferForm}
                className='exchange__input'
                value={selectedCoinsNum}

                defaultValue=''
                placeholder={`${minCoinsCost / selectedCoinCost}`}
                onChange={(e) => {
                  const newCoinsNum = Number(e.target.value)
                  if (selectedCoinCost * newCoinsNum < minCoinsCost && !coinsNumErrorMessage) {
                    setCoinsNumErrorMessage(true);
                  }

                  if (selectedCoinCost * newCoinsNum >= minCoinsCost && coinsNumErrorMessage) {
                    setCoinsNumErrorMessage(false);
                  }

                    setSelectedCoinsNum(e.target.value);
                }}
                onFocus={() => {
                  if (selectedCoinCost * Number(selectedCoinsNum) < minCoinsCost && !coinsNumErrorMessage) {
                    setCoinsNumErrorMessage(true);
                  }
                }}
              />
            </div>
            {coinsNumErrorMessage && (
              <p className="exchange__wrapper-error">Min number of {selectedCoinToExchange} to exchange = {minCoinsCost / selectedCoinCost}</p>
            )}
          </div>
          <button
            className="exchange__deposit"
            onClick={async () => {
              if (selectedCoinCost * Number(selectedCoinsNum) < minCoinsCost && !coinsNumErrorMessage) {
                setCoinsNumErrorMessage(true);
                return 0;
              }

              if (!authorized) {
                return navigate('/login');
              }

              const depositData = {
                userId: userId, // Assuming `user.id` is available in your component state or props
                currency: selectedCoinToExchange, // Example: "BTC"
                amount: Number(selectedCoinsNum), // Number of coins to deposit
              };
          
              try {
                // Make a request to the local PHP API to save the deposit
                const response = await fetch('https://coin24.store/crypto_exchange_api/saveDeposit.php', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(depositData),
                });
          
                const result = await response.json();
          
                if (result.status === 'success') {
                  setVisibleTransferForm(true);
                } else {
                  alert(`Error: ${result.message}`);
                }
              } catch (error) {
                alert('Failed to save deposit. Please try again.');
              }
            }}
            disabled={coinsNumErrorMessage || visibleTransferForm}
          >
            Deposit
          </button>
        </div>

        {visibleTransferForm && (
          <div className="exchange__payment">
            <h3 className="exchange__payment-title exchange__payment-title--orange">
              More:
            </h3>
            <div className="exchange__payment-expected-alert">
              Payment expected
            </div>
            <h4 className="exchange__payment-subtitle exchange__payment-subtitle--orange">System details for payment (wallet):</h4>
            <input
              type='button' 
              className="exchange__payment-wallet" 
              value={coinsArr.find((coin) => coin.currency === selectedCoinToExchange)?.adress}
              onClick={(e) => {
                navigator.clipboard.writeText(coinsArr.find((coin) => coin.currency === selectedCoinToExchange)?.adress as string).then(() => {
                  alert("Copied to clipboard!");
                }).catch(err => {
                  console.error("Failed to copy text: ", err);
                });
              }}
            />
            <p className="exchange__payment-caption">The money will appear on your balance in 5-10 minutes after payment</p>
          </div>
        )}

        <div className="exchange__alarm">
          Attention! All coin24.io users are requested to read {' '}
          <Link className='exchange__alarm-link' to="/services-to-the-exchange">important information</Link>
        </div>
        <div className="exchange__bounty">
          <div className="exchange__bounty-box">
            <h2 className="title">Bounty-program</h2>
            <p className="exchange__bounty-text">
              Take your bonus! Take part in our Bounty Bonus Program! Leave your honest reviews and get real money for them!
            </p>
            <Link to="/bounty-programma" className="button">Details</Link>
          </div>
          <img src="/images/bounty_image.svg" alt="bountyimage" className="exchange__bounty-image" />
        </div>
      </div>
    </section>
    <section className="fast-and-safe-exchange">
      <div className="container">
        <h2 className="title">
          Fast and safe cryptocurrency exchange service in Czechia, Ukraine, Poland, and Georgia!
        </h2>
        <div className="fast-and-safe-exchange__box">
          <h4 className="fast-and-safe-exchange__subtitle fast-and-safe-exchange__subtitle--orange fast-and-safe-exchange__subtitle--left mb-25">Looking for a profitable USDT exchange?</h4>
          <p className="fast-and-safe-exchange__text">
            The round-the-clock exchange of bitcoins, litecoins, ethereum, tether, and other cryptocoins has now become possible thanks to the automatic crypto exchange Coin24.io.
          </p>
          <p className="fast-and-safe-exchange__text">
            Buying cryptocurrency in a few clicks is no longer a problem! On our website, you can deposit or withdraw electronic currencies by any payment method - cash, bank transfer, PrivatBank cards (Privat24), Monobank, Visa/MasterCard, and others. Popular directions include buying USDT for hryvnias, exchanging USDT for cash, exchanging USDT for Monobank, and many others.
          </p>
          <p className="fast-and-safe-exchange__text">
            We are a service for buying and selling cryptocurrencies in Ukraine. Coin24.io is very fast, and you can see for yourself. It takes only a couple of minutes to buy Bitcoin, ether, tether or other altcoin, because the exchanger works automatically. Buying USDT in Kyiv has never been so reliable and easy!
          </p>
          <h4 className="fast-and-safe-exchange__subtitle fast-and-safe-exchange__subtitle--orange fast-and-safe-exchange__subtitle--centered">
            Don't delay, invest in crypto with the best on the market
          </h4>
        </div>
      </div>
    </section>
    <section className="numbers-count">
      <div className="container numbers-count__container">
        <div className="numbers-count__box numbers-count__box--1">
          <p className="numbers-count__text">More</p>
          <div className="numbers-count__info">
            <img src="/images/num-exchange.svg" alt="" className="numbers-count__image" />
            <h4 className="numbers-count__num">{exchangesNum}</h4>
          </div>
          <p className="numbers-count__text">exchanges per day</p>
        </div>
        <div className="numbers-count__box numbers-count__box--2">
          <p className="numbers-count__text">Over</p>
          <div className="numbers-count__info">
            <img src="/images/num-clients.svg" alt="" className="numbers-count__image" />
            <h4 className="numbers-count__num">{customersNum}K</h4>
          </div>
          <p className="numbers-count__text">satisfied customers</p>
        </div>
        <div className="numbers-count__box numbers-count__box--3">
          <p className="numbers-count__text">More</p>
          <div className="numbers-count__info">
            <img src="/images/num-finance.svg" alt="" className="numbers-count__image" />
            <h4 className="numbers-count__num">{yearsNum}</h4>
          </div>
          <p className="numbers-count__text">years in the financial market</p>
        </div>
        <div className="numbers-count__box numbers-count__box--4">
          <p className="numbers-count__text">Top</p>
          <div className="numbers-count__info">
            <img src="/images/num-coins.svg" alt="" className="numbers-count__image" />
            <h4 className="numbers-count__num">{totalCoinsNum}</h4>
          </div>
          <p className="numbers-count__text">coins to exchange</p>
        </div>
      </div>
    </section>
    <section className="about-service">
      <div className="container">
        <h2 className="title about-service__title">
          About Coin24 Service
        </h2>

        <div className="about-service__box">
          <p className="text about-service__text">
            <span className="about-service__text--bold">Coin24.io</span> is a service on the territory of Ukraine that provides the
            service of buying and selling digital assets. Also, it is an online service that provides the opportunity to exchange any
            cryptocurrency for fiat funds (dollars, euros, hryvnias, etc.), using the most popular payment systems.
          </p>

          <p className="text about-service__text">
            Our company has been providing its services on the financial market since 2011. The Coin24.io project
            was founded in August 2018. The main direction of the project is the purchase and sale of cryptocurrency
            for cash and non-cash funds, the sale of wallets and the ordering of the necessary cryptocurrency in the quantity.
          </p>
        </div>
        <Link to="/about-us" className="button button--centered">Read more</Link>
      </div>
    </section>
    <section className="benefits">
      <div className="bg-ellipse"></div>
      <div className="container">
        <h2 className="title benefits__title">
          Our benefits
        </h2>
        <div className="benefits__box">
          <div className="benefits__card">
            <div className="benefits__card-icon benefits__card-icon--1"></div>
            <h3 className="benefits__card-title">Some</h3>
            <p className="benefits__card-text text">
              Multi-level server protection. Digital assets security and control system. The service was added to authoritative monitoring
              and listings. Funds receipts guarantee, subject to compliance with the service exchange rules.
            </p>
          </div>
          <div className="benefits__card">
            <div className="benefits__card-icon benefits__card-icon--2"></div>
            <h3 className="benefits__card-title">Profitable</h3>
            <p className="benefits__card-text text">
              Always up-to-date and profitable exchange rates of the world's leading stock exchanges. No hidden payments or additional
              commissions. Fixation and agreement of the exchange rate with the client by cash exchange condition.
            </p>
          </div>
          <div className="benefits__card">
            <div className="benefits__card-icon benefits__card-icon--3"></div>
            <h3 className="benefits__card-title">Modern</h3>
            <p className="benefits__card-text text">
              The convenient and modern resource. Best world's crypto exchange rates for transactions automatic search.
              Cryptocurrencies automatically exchange using bank cards. An accessible and understandable interface for all.
            </p>
          </div>
          <div className="benefits__card">
            <div className="benefits__card-icon benefits__card-icon--4"></div>
            <h3 className="benefits__card-title">Informative</h3>
            <p className="benefits__card-text text">
              The service informs and minimizes clients expenses, which are not related to the commissions of this exchanger.
              Agreeing to the exchange conditions with the manager in order's chat, when exchanging for cash.
            </p>
          </div>
          <div className="benefits__card">
            <div className="benefits__card-icon benefits__card-icon--5"></div>
            <h3 className="benefits__card-title">Referral program</h3>
            <p className="benefits__card-text text">
              A profitable two-level affiliate program. The opportunity not only to exchange profitably,
              but also to earn. Individual discount programs and a loyalty program for regular customers. Cashback system.
            </p>
          </div>
          <div className="benefits__card">
            <div className="benefits__card-icon benefits__card-icon--6"></div>
            <h3 className="benefits__card-title">Privacy</h3>
            <p className="benefits__card-text text">
              Information about users is not stored on the service. Strict confidentiality is observed.
              We respect your wish not to disclose personal data about yourself. Information about orders is stored in encrypted form
            </p>
          </div>
          <div className="benefits__card">
            <div className="benefits__card-icon benefits__card-icon--7"></div>
            <h3 className="benefits__card-title">Functionally</h3>
            <p className="benefits__card-text text">
              Payment systems for withdrawing funds and direct exchanges wide list. Exchange in a currency convenient
              for the client. High transaction and order processing speed. 24/7 automatic cryptocurrency exchange.
            </p>
          </div>
          <div className="benefits__card">
            <div className="benefits__card-icon benefits__card-icon--8"></div>
            <h3 className="benefits__card-title">Profitable partnership</h3>
            <p className="benefits__card-text text">
              We provide advanced tools with high conversion rates. API and other integration
              formats. Help and support in connecting to the service. Box solutions provided.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="articles">
      <div className="container">
        <h3 className="title articles__title mb-25">Useful articles</h3>

        <div className="articles__box">
          <div className="articles__card">
            <h3 className="articles__card-title">
              USDT and Financial Transparency: Analyzing Reporting in the USDT Ecosystem
            </h3>
            <p className="articles__card-text">
              Tether Limited issued USDT in 2014. Many users wonder where to store USDT, how to exchange it, or how to use a USDT wallet. However, beyond these questions, it is also important to pay attention to financial transparency within the USDT ecosystem.
            </p>
            <div className="articles__card-box">
              <p className="articles__card-date">08.08.2024</p>
              <a href="" className="articles__card-link">Read...</a>
            </div>
          </div>
          <div className="articles__card">
            <h3 className="articles__card-title">
              USDT and Education: The Role of Stable Cryptocurrencies in Financial Literacy and Developing Financial Skills
            </h3>
            <p className="articles__card-text">
              With the growing interest in cryptocurrencies and digital assets, there is a need to integrate financial education with an understanding of these new forms of exchange. This article explores the role of stable cryptocurrencies, particularly USDT, in shaping financial literacy and developing financial skills.                  </p>
            <div className="articles__card-box">
              <p className="articles__card-date">25.07.2024</p>
              <a href="" className="articles__card-link">Read...</a>
            </div>
          </div>
          <div className="articles__card">
            <h3 className="articles__card-title">
              USDT and the Cryptocurrency Exchange Market: Analyzing the Impact of USDT on Liquidity and Trading Volumes on Exchanges
            </h3>
            <p className="articles__card-text">
              USDT, also known as Tether, is one of the most popular stablecoins in the cryptocurrency market. The main advantage of USDT lies in its peg to the US dollar, which ensures
              price stability and reduces the volatility typical of most cryptocurrencies. In this article, we will examine how USDT affects the activities
              of cryptocurrency exchanges, including trading liquidity, transaction volumes, and the price dynamics of other cryptocurrencies.
            </p>
            <div className="articles__card-box">
              <p className="articles__card-date">16.07.2024</p>
              <a href="" className="articles__card-link">Read...</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>);
}

export default HomePage;